/* Global Styles */
/* Put those styles that can't applied via props */

/* Global styles for react-datepicker ---------------*/

.react-datepicker {
  z-index: 9999 !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 3 !important;
}
.chakra-input__right-element {
  z-index: 0 !important;
}
/* --------------------------------------------------*/

/* Keyframes --------------------------------------- */
@keyframes phone-ring {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.4);
  }
  25% {
    box-shadow: 0 0 0 5px rgba(66, 153, 225, 0.4),
      0 0 0 8px rgba(66, 153, 225, 0.2);
  }
  25% {
    box-shadow: 0 0 0 6px rgba(66, 153, 225, 0.4),
      0 0 0 8px rgba(66, 153, 225, 0.2);
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: #ccc;
}
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #c2c2c2;
}

.private-router-wrapper {
  display: flex;
}
.private-router-wrapper > div {
  width: 100%;
}

/* --------------------------------------------------*/
.logo-icon {
  color: gray;
}
@media print {
  body * {
    visibility: hidden;
  }

  #feedback-detail-print * {
    visibility: visible;
    position: static;
  }
  #booking-detail-print {
    margin-top: 20px;
  }
  #booking-detail-print * {
    visibility: visible;
    position: static;
    background-color: white !important;
  }

  #booking-pdf-table tr {
    border-bottom: 1px solid gray;
  }
  .booking-pdf-table-dashed-border {
    border-bottom: 1px dashed gray;
  }
  .booking-pdf-table-solid-border {
    border-bottom: 1px solid gray;
  }
  .booking-pdf-description {
    color: #555;
  }
  @page {
    size: auto;
  }
  .page-break {
    margin-top: 10rem;
    display: block;
    page-break-before: auto;
  }
}

/* feedback dashboard pdf */
@media print {
  body * {
    visibility: hidden;
  }
  #feedback-print {
    margin: 0 !important;
    padding: 0 !important;
  }
  #feedback-print * {
    visibility: visible;
    position: static;
    background-color: white !important;
  }

  #feedback-pdf-table-body {
    margin: 0 !important;
    padding: 0 !important;
  }
  #feedback-pdf-table-body tr {
    border-bottom: 1px solid #d3d3d3;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #feedback-filter-block > * {
    font-family: 'Roboto', sans-serif;
  }
  .filter-keyword-text {
    font-weight: 700;
  }
  #filter-header-text {
    font-weight: 900;
    font-size: 18px;
    display: block;
  }
  .hide-in-print {
    display: none;
  }
}

/* feedback response rating background color */
.rating-tag {
  display: block;
  text-align: center;
  max-width: 3rem;
  font-weight: 500;
  line-height: 2;
  min-height: 1.5rem;
  min-width: 1.5rem;
  font-size: 0.875rem;
  color: #fff;
  border-radius: 9999px;
}
#tag-background-color-red {
  background-color: #f56565 !important;
}
#tag-background-color-orange {
  background-color: #ed8936 !important;
}
#tag-background-color-green {
  background-color: #68d391 !important;
}

@media all {
  .page-break {
    display: none;
  }
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

/*qr code (QRScanDetailPDF)*/
@media print {
  #qr-scan-print * {
    visibility: visible;
    position: static;
  }
  .qr-page-break {
    page-break-after: always !important;
  }

  #qr-svg {
    width: 250px;
    height: 250px;
  }
}
/*Compose Message Header*/
.tox .tox-editor-header {
  z-index: 0 !important;
}
.tox-tinymce {
  border: 2px solid #eee;
  border-radius: 3px !important;
}

.custom-select-container {
  position: relative;
  box-sizing: border-box;
}
.outer-line-span {
  z-index: 9999;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}
.all-content-container {
  align-items: center;
  background-color: #ffffff;
  border-color: inherit;
  border-radius: 0.125rem;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 1px 0px #eff0f6;
  cursor: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 35px;
  outline: 0 !important;
  position: relative;
  transition: all 0.35s ease-in-out;
  box-sizing: border-box;
  opacity: 1;
}
.text-placeholder-and-input-field-container {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  padding: 0 10px;
  overflow: hidden;
  box-sizing: border-box;
}
.text-placeholder {
  color: #000000;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}
.input-field-inner-container {
  box-sizing: content-box;
  width: 100%;
  background: 0px center;
  border: 0px;
  font-size: inherit;
  opacity: 1;
  outline: 0px;
  padding: 0px;
  color: inherit;
}
.input-field {
  width: 100%;
  margin-left: 4px;
  outline: none !important;
}
.input-field::placeholder {
  color: #000000;
}
.seperator-arrow-outer-container {
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.line-seperator {
  align-self: stretch;
  background-color: #edf2f7;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}
.arrow-inner-container {
  color: #000000;
  display: flex;
  padding: 7px 8px;
  box-sizing: border-box;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease;
  transition-delay: 0s;
}
.arrow-svg {
  display: inline-block;
  fill: black;
  line-height: 1;
  stroke-width: 0;
}

.modal-box {
  width: 100%;
  z-index: 9999;
  position: absolute;
  margin-top: 6px;
  padding-top: 4px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #ddd;
  overflow-y: auto;
  max-height: 330px;
}
.list-item {
  padding-left: 10px;
  font-size: 13px;
  font-weight: 300;
  height: 36px;
  color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
}
.list-item-disabled {
  opacity: 0.4;
}
.list-item:hover {
  background-color: #dbe7ff;
  opacity: 0.9;
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.3;
  right: 10px;
  line-height: 9px;
  font-size: 0.8em;
}
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting_asc_disabled,
table.dataTable thead > tr > th.sorting_desc_disabled,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc_disabled {
  cursor: pointer;
  position: relative;
  padding-right: 26px;
}
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 1;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
  bottom: 50%;
  content: '▲';
}
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  top: 50%;
  content: '▼';
}

/*blink css*/
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* Custom Datepicker */
.custom-datepicker-wrapper:has(.custom-datepicker-input:focus) {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

.animated-second-screen-welcome-text {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.chakra-collapse:has(.focusable-accordian-panel) {
  display: flex !important;
}
.chakra-collapse:has(.focusable-accordian-panel) > div {
  flex: 1;
}
